const actions = {
  setDefaultLang: (state, { payload }) => {
    const { language } = payload
    return {
      ...state,
      language: language,
    }
  },
  setLangPl: (state, { payload }) => {
    return {
      language: "pl",
    }
  },
  setLangEn: (state, { payload }) => {
    return {
      language: "en",
    }
  },
}

function appReducer(state, action) {
  switch (action.type) {
    case "setDefaultLang":
      return actions.setDefaultLang(state, action)
    case "setLangPl":
      return actions.setLangPl(state, action)
    case "setLangEn":
      return actions.setLangEn(state, action)
  }
}

export default appReducer
