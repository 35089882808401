import { request } from "./request"
import WOOCOMMERCE from "../env/WOOCOMMERCE"
const { KEY, SECRET } = WOOCOMMERCE.APIAUTH

class WPAPI {
  constructor() {
    this.route = "/wp-json/wc/v3"
  }

  getAllProducts() {
    const url = this.route + `/products`
    const config = {
      method: "GET",
      withCredentials: true,
      auth: {
        username: KEY,
        password: SECRET,
      },
    }
    return request(url, config)
  }

  getAllCoupons() {
    const url = this.route + `/coupons?per_page=100`
    const config = {
      method: "GET",
      withCredentials: true,
      auth: {
        username: KEY,
        password: SECRET,
      },
    }
    return request(url, config)
  }
  postCreateOrder(customer, cart, shipping, coupon = {}, deliveryPoint) {
    let pointId = ""
    let pointLabel = ""
    const { name, address } = deliveryPoint
    if (Object.keys(deliveryPoint).length > 0) {
      pointId = name
      pointLabel = address.line1 + " " + address.line2
    }
    const url = this.route + `/orders`
    const config = {
      method: "POST",
      withCredentials: true,
      auth: {
        username: KEY,
        password: SECRET,
      },
      data: {
        billing: customer,
        shipping: customer,
        customer_note: customer.note,
        line_items: cart,
        shipping_lines: [shipping],
        ...coupon,
        meta_data: [
          {
            key: `_polkurier_point_id`,
            value: pointId,
          },
          {
            key: `_polkurier_point_label`,
            value: pointLabel,
          },
        ],
      },
    }

    return request(url, config)
  }

  changeOrder(id, data = { status: "completed" }) {
    const url = this.route + `/orders/${id}`
    const config = {
      method: "POST",
      withCredentials: true,
      auth: {
        username: KEY,
        password: SECRET,
      },
      data,
    }

    return request(url, config)
  }

  searchByPaymentId(paymentId) {
    const url = this.route + `/orders?search=${paymentId}`
    const config = {
      method: "GET",
      withCredentials: true,
      auth: {
        username: KEY,
        password: SECRET,
      },
    }

    return request(url, config)
  }

  search(phrase = "") {
    const urlProducts = this.route + `/products?search=${phrase}`
    const configProducts = {
      method: "GET",
      withCredentials: true,
      auth: {
        username: KEY,
        password: SECRET,
      },
    }
    const orders = () => request(urlProducts, configProducts)

    const urlPosts = `/wp-json/wp/v2/posts?search=${phrase}`
    const configPosts = {
      method: "GET",
    }
    const posts = () => request(urlPosts, configPosts)

    const urlPages = `/wp-json/wp/v2/pages?search=${phrase}`
    const configPages = {
      method: "GET",
    }
    const pages = () => request(urlPages, configPages)

    return Promise.all([orders(), posts(), pages()])
  }

  signToNewsletter(email, query = {}) {
    const url = "/wp-json/contact-form-7/v1/contact-forms/345/feedback"
    const data = new FormData()
    data.append("email", email)

    const config = {
      method: "POST",
      query: query,
      multipart: true,
      data,
    }
    return request(url, config)
  }

  sendMail(email, sender, message, query = {}) {
    const url = "/wp-json/contact-form-7/v1/contact-forms/223/feedback"
    const data = new FormData()
    data.append("email", email)
    data.append("sender", sender)
    data.append("message", message)

    const config = {
      method: "POST",
      query: query,
      multipart: true,
      data,
    }
    return request(url, config)
  }

  getDeliveryOptions() {
    const url = "/wp-json/wc/v3/shipping/zones"
    const config = {
      method: "GET",
      withCredentials: true,
      auth: {
        username: KEY,
        password: SECRET,
      },
    }
    return request(url, config).then((data) => {
      const [selected] = data.filter((el) => el.name === "Remote")
      const url = `/wp-json/wc/v3/shipping/zones/${selected.id}/methods`
      const config = {
        method: "GET",
        withCredentials: true,
        auth: {
          username: KEY,
          password: SECRET,
        },
      }
      return request(url, config)
    })
  }

  createProductReview(id, form) {
    const url = "/wp-json/wc/v3/products/reviews"
    const config = {
      method: "POST",
      withCredentials: true,
      auth: {
        username: KEY,
        password: SECRET,
      },
      data: {
        product_id: Number(id),
        ...form,
      },
    }
    return request(url, config)
  }
  retrieveAllReviews() {
    const url = `/wp-json/wc/v3/products/reviews`
    const config = {
      method: "GET",
      withCredentials: true,
      auth: {
        username: KEY,
        password: SECRET,
      },
    }
    return request(url, config)
  }

  retrieveProductReview(id) {
    const url = `/wp-json/wc/v3/products/reviews?product=${id}`
    const config = {
      method: "GET",
      withCredentials: true,
      auth: {
        username: KEY,
        password: SECRET,
      },
    }
    return request(url, config)
  }

  commentPost(postId, author, email, comment) {
    const url = "/wp-json/wp/v2/comments"
    const config = {
      method: "POST",
      data: {
        post: postId,
        author_name: author,
        author_email: email,
        content: comment,
      },
    }
    return request(url, config)
  }

  retrievePostComments(postId) {
    const url = `/wp-json/wp/v2/comments/?post=${postId}`
    const config = {
      method: "GET",
    }
    return request(url, config)
  }

  getCollaborators() {
    const url = `/wp-json/get-contributors/v1/POST`
    const config = {
      method: "POST",
      crossDomain: true,
    }
    return request(url, config)
  }
}

const WPApi = new WPAPI()

export default WPApi
