// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-blog-blog-js": () => import("./../../../src/templates/blog/blog.js" /* webpackChunkName: "component---src-templates-blog-blog-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog/post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-blog-single-category-js": () => import("./../../../src/templates/blog/single-category.js" /* webpackChunkName: "component---src-templates-blog-single-category-js" */),
  "component---src-templates-ecommerce-summary-js": () => import("./../../../src/templates/ecommerce/summary.js" /* webpackChunkName: "component---src-templates-ecommerce-summary-js" */),
  "component---src-templates-standard-contact-js": () => import("./../../../src/templates/standard/contact.js" /* webpackChunkName: "component---src-templates-standard-contact-js" */),
  "component---src-templates-standard-cookies-js": () => import("./../../../src/templates/standard/cookies.js" /* webpackChunkName: "component---src-templates-standard-cookies-js" */),
  "component---src-templates-standard-home-js": () => import("./../../../src/templates/standard/home.js" /* webpackChunkName: "component---src-templates-standard-home-js" */),
  "component---src-templates-standard-regulations-js": () => import("./../../../src/templates/standard/regulations.js" /* webpackChunkName: "component---src-templates-standard-regulations-js" */),
  "component---src-templates-standard-returns-js": () => import("./../../../src/templates/standard/returns.js" /* webpackChunkName: "component---src-templates-standard-returns-js" */),
  "component---src-templates-standard-rodo-js": () => import("./../../../src/templates/standard/rodo.js" /* webpackChunkName: "component---src-templates-standard-rodo-js" */),
  "component---src-templates-standard-tag-js": () => import("./../../../src/templates/standard/tag.js" /* webpackChunkName: "component---src-templates-standard-tag-js" */),
  "component---src-templates-standard-tags-js": () => import("./../../../src/templates/standard/tags.js" /* webpackChunkName: "component---src-templates-standard-tags-js" */)
}

