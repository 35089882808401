import { WPApi } from "./api"
import { loadState, loadCart, loadCustomer, setShippings } from "./state"

function loadFromStorageToStore(store) {
  const persistantState = loadState()
  if (persistantState) {
    store.dispatch(loadCart(persistantState.Cart))
    store.dispatch(loadCustomer(persistantState.Customer))
  }
}

function getShippingMethods() {
  return WPApi.getDeliveryOptions().then((data) => {
    // const [rangeObject] = data.filter((el) =>
    //   Object.keys(el.settings).includes("min_amount")
    // )
    const notFreeShippings = data.filter(
      (el) => !Object.keys(el.settings).includes("min_amount")
    )
    const freeShippings = data.filter((el) => el.method_id.includes("free"))
    // const { min_amount } = rangeObject.settings
    console.log("here")

    const shippings = {
      always: notFreeShippings.map((el) => {
        return {
          method_id: el.method_id,
          method_title: el.title,
          total: Number(el.settings.cost.value),
        }
      }),
      free: freeShippings.map((el) => {
        return {
          method_id: el.method_id,
          method_title: el.title,
          total: 0,
        }
      }),
    }
    return shippings
  })
}

function initShippingMethods(store) {
  return WPApi.getDeliveryOptions().then((data) => {
    const notFreeShippings = data.filter(
      (el) => !Object.keys(el.settings).includes("min_amount")
    )
    const freeShippings = data.filter((el) => el.method_id.includes("free"))
    const shippings = {
      always: notFreeShippings.map((el) => {
        return {
          method_id: el.method_id,
          method_title: el.title,
          total: Number(el.settings.cost.value),
        }
      }),

      free: freeShippings.map((el) => {
        return {
          method_id: el.method_id,
          method_title: el.title,
          total: 0,
        }
      }),
    }

    store.dispatch(setShippings(shippings))
  })
}

function onAppInit(store) {
  loadFromStorageToStore(store)
  initShippingMethods(store)
}

export { onAppInit, getShippingMethods }
